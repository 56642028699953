<template>
  <div class="outer-page">
    <div class="select">
      <Select
        style="width: 330px"
        placeholder="请选择养殖场"
        @on-change="changeList"
      >
        <Option
          :value="item.id"
          v-for="(item, index) in mapList"
          :key="index"
          >{{ item.mapName }}</Option
        >
      </Select>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {
    mapList: {
      type: [Object, Array],
      default: [],
    },
  },
  data() {
    return {};
  },
  methods: {
    //选择养殖场
    changeList(value) {
      this.$emit("on-change", value);
    },
  },
};
</script>

<style lang="less" scoped>
@import url("./index.less");
/deep/ .ivu-select-dropdown {
  // background: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  // border: 1px solid #00fff7;
}
/deep/ .ivu-select-item {
  color: #fff;
}
/deep/ .ivu-select-item:hover {
  // background: rgba(23, 34, 60, 0.4);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
}
/deep/ .ivu-select-item-focus {
  // background: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
}
/deep/ .ivu-select-selection {
  // background: rgba(23, 34, 60, 0.6);
  // border: 1px solid rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  border: 1px solid #00fff7;
}
/deep/.ivu-select-visible .ivu-select-selection {
  border-color: rgba(23, 34, 60, 0.6);
  outline: 0;
  box-shadow: 0 0 0 2px rgb(23 34 60 / 20%);
}
/deep/ .ivu-select {
  color: #fff;
}
/deep/ .green-theme .ivu-select-item-selected,
.green-theme .ivu-select-item-selected:hover {
  color: #fff;
}
/deep/ .ivu-select-selection:hover {
  // border-color: rgba(23, 34, 60, 0.6);
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  border: 1px solid #00fff7;
}
/deep/ .ivu-select-selection:focus {
  background: linear-gradient(
    180deg,
    rgba(14, 56, 50, 0.28) 0%,
    rgba(3, 42, 34, 0.81) 100%
  );
  border: 1px solid #00fff7;
}
.outer-page {
  width: 100%;
  height: 100%;
}
</style>